/* body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
    monospace;
} */

#modal > div {
  z-index: 5;
}