.alignEdit {
  float: right;
}
.contactType {
  display: flex;
  margin: 10px;
}
.generalDetails > h3{
  color: #0173c6;
}
.contents >article> section {
  display: flex;
  flex-flow: column wrap;
  max-height: 300px;
}

.contents >article> section {
  padding-left: 30px;
  padding-bottom: 0px;
}

.saInputLabel{
  color:#0173c6;
  font-weight: 600;
}
.learnMore > article > section {
  height: 350px;
  /* overflow-y: scroll; */
}

.saCheckboxDisabled * {
  color: #666666;
}

.adjustBan {
  border-top: 1px solid #ccc;
  padding-right: 0px;
}

.learnCard{
  background: rgb(243 245 248);
  padding: 10px 10px;
  border: 1px solid rgb(243 245 248);
  width: auto;
  height: auto;

}
.learnCard > span {
  font-size: 14px;
}
.confirm{
display: flex;
justify-content: center;
}
.confirmText{
  margin:10px 10px;
}

.confirmText > article > header {
  margin:10px 10px;
  header {
    position: static;
    margin-bottom: 40px;
    h2 {
      font-size: 23px;
    }
  }
}
input[type="checkbox"]{
  margin-right: 1rem;
}

.NextModal{
  width:50%;
  height: 60vh;
  margin-left:25rem;
  margin-top: -1rem;
  overflow-y: scroll;
}
.learnMore{
  width:50%;
   margin-left:10rem;
  margin-top: -1rem;
}
.alignButtons{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
}

.AddNextModal{
  width:50%;
  height: 60vh;
  margin-left:15rem;
  margin-top: -1rem;
  overflow-y: scroll;
}
.btn-continue > button{
  background-color: rgb(7 80 151);
  float:right
}
.actions{
  margin-top:1rem;
  float: right;
}
.banner{
  display: flex;
  justify-content: right;
  align-items: flex-end;
  border: 1px solid #fdeae7;
  background-color: rgb(253, 234, 231);
  padding: 20px;
  color: #cd5234;
}
.adjustBan{
  margin-top:-5px;
}
.contact-accord > .accordion-actions{
  justify-content: space-between !important;
}
.buttonsAct{
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
}
.buttonsAct > button:first-child {
  font-weight: 400;
}
.buttonsAct > button:last-child {
  margin-left: 15px;
}
.locationName{
  border: "1px solid grey";
  margin: "1rem";
  padding: "1rem";
}
.adjustLocItems{
  margin: "1rem";
  padding: "1rem";
}
.alignLeft{
  float: left;
}

.hidden {
  display: none !important;
}
.helpicon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.helpicon svg path{
  fill: hsl(205,99%,39%);
}
